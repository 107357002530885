h1{
    font-size: 3.8rem;
}
h2{
    font-size: 2rem;
}
h3{
    font-size: 1.5rem;
}
h1,h2,h3{
    text-align: center;
}

.contenedor {
    max-width: 75rem;
    margin: 0 auto;
}

.sombra{
    -webkit-box-shadow: 0px 5px 15px 0px rgba(112,112,112,0.48);
    -moz-box-shadow: 0px 5px 15px 0px rgba(112,112,112,0.48);
    box-shadow: 0px 5px 15px 0px rgba(112,112,112,0.48);
    background-color: var(--blanco);
    padding: 2rem;
    border-radius: 1rem;
    
}

.head {
    height: 150px;
    width: 100%;
    position: relative;
}
.head .circle{
    position: absolute;
    width: 100%;
    height: 320px;
    border-radius: 50%;
    background-color: #262626;
    bottom: 20px;
}

.head .img {
    width: 150px;
    height: 150px;
    position: absolute;
    padding: 5px;
    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #7a632c 62.5%, #5d4a1f 100%);
    bottom: -10%;
    left: 50%;
    transform: translate(-50%);
    border-radius: 50%;

}

.head .img .avatar{
    width: 100%;
    height: 100%;
}

.info{
    background-image: url(../img/abogados.jpg);
    background-repeat: no-repeat;
    background-size:cover;
    height: 400px;
    position: relative;
    margin-bottom: 2rem;
    background-position: 50% 20%;
}

.contenido{
    position: absolute;
    background-color:rgba(0, 0, 0, .8 );
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img1{background-image: url(../img/familiar.png);}
.img2{background-image: url(../img/laboral.jpg);}
.img3{background-image: url(../img/amparo.png);}
.img4{background-image: url(../img/convenios.jpg);}
.img5{background-image: url(../img/derecho-civil.jpg);}
.img6{background-image: url(../img/mercantil.jpg);}
.img7{background-image: url(../img/administracion.jpg);}
.img8{background-image: url(../img/traduccion.jpg);}

.img1, .img2, .img3, .img4, .img5, .img6, .img8 {
    background-repeat: no-repeat;
    background-size:cover;
    height: 100%;
    width: 100%;
    position: absolute;
    background-position: center;
} 

.img7{
    background-repeat: no-repeat;
    background-size:cover;
    height: 100%;
    width: 100%;
    position: absolute;
    background-position: left;
}
.espaciado{
    padding-bottom:15px ;
}

.vineta{
    padding-left: 2rem;
    list-style-type: none;
}

.espaciados{
    padding-left: 1rem;
    background-image: url(../img/flecha3.png);
    background-position: 0 15%;
    background-size: .5rem .5rem;
    background-repeat: no-repeat;
}

.card{
    height: 400px;
    max-width: 500px;
    position: relative;
}

.card .face{
    backface-visibility: hidden;
    overflow: hidden;
    transition: .5s;

}
.card .front {
    transform: perspective(600px) rotateY(0deg);
}

.card .back {
    transform: perspective(600px) rotateY(180deg);
    background-color: #BD1119;
    color: white;
    height: 100%;
  
}

.card:hover .front{
    transform: perspective(600px) rotateY(180deg);
}

.card:hover .back {
    transform: perspective(600px) rotateY(360deg);
}

.color_oro{
    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #7a632c 62.5%, #5d4a1f 100%);
}

.texto {
    background: -webkit-linear-gradient(#FEDB37, #7a632c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: rgb(255, 255, 255);  
  }

.toolbar{
    flex-wrap: wrap;
}

@media (min-width: 768px){
    .toolbar{
        flex-wrap: nowrap;
    }
}

.color_boton{
    background-color: #BD1119;
}

