.contenedor_mapa{
    min-width: 250px;
    height: 320px;
    margin: 0 auto 10px auto;
}

@media (min-width: 768px){
    .contenedor_mapa{
        width:500px;
    }
}

